<template>
  <div class="Documentation">

    <formTitleComponent />
      <div class="container-fluid">
        <loading
          :active.sync="documentationInProcess"
          :can-cancel="false"
          :is-full-page="true"
          color="#F9BF3B">
        </loading>

        <b-modal v-model="addDocumentationModal" id="addDocumentationModal" size="lg" hide-footer title="Ajouter une note">
          <b-textarea
            v-model="notes"
            placeholder="Ajouter une note"
            rows="6"
            max-rows="6"
          >
          </b-textarea>
          <div class="text-center">
            <b-button class="mt-3" variant="outline-dark" @click="addDocumentationModal = false">Annuler</b-button>
            <b-button class="mt-3 ml-4" variant="outline-success" @click="createDocumentation" 
              :disabled="$v.notes.$invalid">
              Ajouter
            </b-button>
          </div>
        </b-modal>


        <h1 class="form_div_title text-center">Documentation</h1>
        <div class="form_div">
          <div class="form_content">
            <div class="row">
              <div class="align-self-center col-12 col-sm-6">
                <strong> Notes spécifiques</strong>
              </div>
            </div>
            <div class="row">
              <div class="col-12 text-right">
                <b-button variant="outline-success" pill @click="addDocumentationModal = true">
                  <v-icon name="plus"/> Ajouter une note
                </b-button>
              </div>
            </div>  
            <div 
              v-for="documentation in documentations"
              :key="documentation.id"
              class="mt-4"
            >
              <p style="white-space: pre-line">
                <b-textarea
                  v-model="documentation.notes"
                  placeholder="Ajouter une note"
                  rows="6"
                  max-rows="6"
                >
                  {{documentation.notes}}
                </b-textarea>
              </p>
              <div class="text-right">
                <b-button
                  variant="outline-primary" class="ml-1" size="sm"
                  @click="modifyDocumentation(documentation)"
                >
                  Modifier
                </b-button>
                <b-button
                  variant="outline-danger" class="ml-1" size="sm"
                  @click="deleteDocumentation(documentation)"
                >
                  Supprimer
                </b-button>
              </div>
              <hr>
            </div>
            <div class="row">
              <div class="align-self-center col-12 col-sm-6">
                <strong> Informations sur les requêtes </strong>
              </div>
            </div>
            <table class="mt-4 table w-auto">
              <thead>
                <tr>
                  <th></th>
                  <th>Signification</th>
                  <th>Exemple de requête</th>
                  <th>Exemples de résultat positifs</th>
                  <th>Exemples de résultat négatifs</th>
                </tr>
              </thead>
            <tr>
              <td>  |</td>
              <td>OU booléen</td>
              <td>investisseur|investissements</td>
              <td>investisseur</td>
              <td>investit</td>
            </tr>
            <tr>
              <td>  \w*\W+(?:\w+\W+){0,3}?</td>
              <td>Espacement entre 0 et 3 mots</td>
            <td>  investissement\w*\W+(?:\w+\W+){0,3}?immobilier </td>
            <td>  investissement dans l'immobilier<br /> </td>
            <td>  investissement très important et durable dans l'immobilier<br />investissement industriel </td>
            </tr>
            <tr>
              <td>  \w+</td>
              <td>Joker (1 seul caractère)</td>
            <td>\w+est</td>
            <td>test<br />lest</td>
            <td>est<br />ouest</td>
            </tr>
            <tr>
              <td>  \w*</td>
              <td> Joker (1 caractère ou plus)</td>
              <td>lèv\w* </td>
              <td>lève<br />lèvera</td>
              <td>levée</td>
            </tr>
            <tr>
              <td>\b</td>
              <td>Début/fin de mot</td>
              <td>investi\b ou \bpme\b</td>
              <td>investi</td>
              <td>investissement</td>
            </tr>
            </table>

            <p>
              Si une requête comporte plusieurs lignes, les différentes lignes de la requête sont
              combinées avec l'opérateur booléen "ET".
            </p>

            <hr>
            
            <p>
              <strong>Trouver le XPath d'une zone de page web</strong>
            </p>

            <p>
              Je vous conseille d'utiliser le plugin XPath Finder,
              disponible sur <a href="https://addons.mozilla.org/fr/firefox/addon/xpath_finder/">Firefox</a>
              et sur <a href="https://chrome.google.com/webstore/detail/xpath-finder/ihnknokegkbpmofmafnkoadfjkhlogph">Chrome</a>.
            </p>

            <hr>

            <p>
              <strong>Valider votre requête</strong>
            </p>
            <p>
              Vous pouvez tester et valider votre requête via ces différents liens :
              <ul>
                <li>Interne à Decidcrawl via l'onglet <router-link to="/requetes">requete</router-link> puis cliquer sur le ?</li>
                <li>L'outil RegexR : <a href="https://regexr.com/" target="_blank"> ici </a></li>
                <li>L'outil Regex101 : <a href="https://regex101.com/" target="_blank"> ici </a></li>
              </ul>
            </p>
          </div>
        </div>
    </div>
  </div>
</template>

<script>

import { validationMixin } from 'vuelidate'
import { required, minLength } from 'vuelidate/lib/validators'
import { APIDocumentation } from '@/api/APIDocumentation'
import formTitleComponent from '@/components/formTitleComponent'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

const apiDocumentation = new APIDocumentation()


export default {
  name: 'Documentation',
  components: {
    formTitleComponent,
    Loading
  },
  mixins: [validationMixin],
  data: function () {
    return {
      addDocumentationModal: false,
      notes: '',
      documentations: [],
      documentationInProcess: false
    }
  },
  validations: {
    notes: {
      minLength: minLength(2),
      required
    },
  },
  computed: {
     token () {
      return this.$store.state.auth.token
    },
  },
  mounted () {
    this.getDocumentations()
  },
  methods: {
    createDocumentation () {
      this.documentationInProcess = true
      const data = {
        notes: this.notes
      }
      apiDocumentation.createDocumentation(
        this.token, data)
      .then((result) => {
        this.documentations.push(result.data)
        this.addDocumentationModal = false
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('DocumentationCreation createDocumentation API Error : ' + String(error))
      })
      .finally(() => {
        this.documentationInProcess = false
      })
    },

    deleteDocumentation (documentation) {
      this.documentationInProcess = true
      apiDocumentation.deleteDocumentation(
        this.token, documentation.id)
      .then(() => {
        this.documentations = this.documentations.filter((item) => {
          return item.id !== documentation.id
        })
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('DocumentationCreation deleteDocumentation API Error : ' + String(error))
      })
      .finally(() => {
        this.documentationInProcess = false
      })
    },

    getDocumentations () {
      this.documentationInProcess = true
      apiDocumentation.getAllDocumentations(this.token)
      .then((result) => {
        this.documentations = result.data
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('DocumentationCreation getAllDocumentations API Error : ' + String(error))
      })
      .finally(() => {
        this.documentationInProcess = false
      })
    },

    modifyDocumentation (documentation) {
      this.documentationInProcess = true
      apiDocumentation.modifyDocumentation(
        this.token, documentation.id, documentation)
      .then((result) => {
        this.documentations = this.documentations.map((documentation) => {
          if (documentation.id === result.data.id) {
            return result.data
          }
          return documentation
        })
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('DocumentationCreation modifyDocumentation API Error : ' + String(error))
      })
      .finally(() => {
        this.documentationInProcess = false
      })
    }
  }
}
</script>
